var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"proposal__wrap"},[_c('div',{staticClass:"section shadow filter__wrap"},[_c('div',[_c('el-radio-group',{on:{"change":_vm.handleTabs},model:{value:(_vm.table.params.type),callback:function ($$v) {_vm.$set(_vm.table.params, "type", $$v)},expression:"table.params.type"}},[_c('el-radio-button',{attrs:{"label":1}},[_vm._v("待审核")]),_c('el-radio-button',{attrs:{"label":2}},[_vm._v("企业列表")]),_c('el-radio-button',{attrs:{"label":3}},[_vm._v("未充值")]),_c('el-radio-button',{attrs:{"label":4}},[_vm._v("审核失败")]),_c('el-radio-button',{attrs:{"label":5}},[_vm._v("下架企业")]),_c('el-radio-button',{attrs:{"label":6}},[_vm._v("待完善")])],1)],1),_c('div',{staticClass:"flex"},[_c('el-form',{ref:"elFormDom",attrs:{"inline":"","model":_vm.table.params}},[_c('el-form-item',{attrs:{"label":"","prop":"keyword"}},[_c('el-input',{attrs:{"clearable":"","placeholder":"请输入企业名称"},model:{value:(_vm.table.params.keyword),callback:function ($$v) {_vm.$set(_vm.table.params, "keyword", $$v)},expression:"table.params.keyword"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary","icon":"el-icon-search"},on:{"click":_vm.handleSearch}},[_vm._v("查询 ")])],1)],1)],1)]),_c('div',{staticClass:"table__wrap"},[(this.table.params.type === 1 || this.table.params.type === 3 || this.table.params.type === 4)?[_c('VTable',{attrs:{"has-pagionation":"","title":"","addText":"添加","field":_vm.field,"loading":_vm.table.loading,"data":_vm.table.data,"page":_vm.table.params.page,"pageSize":_vm.table.params.count,"total":_vm.table.total,"tree-props":{children: 'children', hasChildren: 'has_child'},"hasPagionation":true,"default-expand-all":true,"showOverflowTooltip":true},on:{"handleSizeChange":_vm.handleSizeChange,"handleCurrentChange":_vm.handleCurrentChange},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.name))]),(row.ali_certify_status > 3)?_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('el-image',{staticStyle:{"width":"15px","height":"15px"},attrs:{"fit":"cover","src":require('@/assets/img/zmxy.png')}}),_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("芝麻企业信用认证")])],1):_vm._e()]}},{key:"action",fn:function(ref){
var row = ref.row;
return [(row.type === '1')?_c('el-button',{attrs:{"type":"text","size":"mini"},on:{"click":function($event){return _vm.showDetail(row)}}},[_vm._v("审核")]):_vm._e(),(row.type === '3' || row.type === '4')?_c('el-button',{attrs:{"type":"text","size":"mini"},on:{"click":function($event){return _vm.showDetail(row)}}},[_vm._v("查看")]):_vm._e()]}}],null,false,257109323)})]:_vm._e(),(this.table.params.type === 2 || this.table.params.type === 5)?[_c('VTable',{attrs:{"has-pagionation":"","title":"","addText":"添加","field":_vm.field2,"loading":_vm.table.loading,"data":_vm.table.data,"page":_vm.table.params.page,"pageSize":_vm.table.params.count,"total":_vm.table.total,"tree-props":{children: 'children', hasChildren: 'has_child'},"hasPagionation":true,"default-expand-all":true,"showOverflowTooltip":true},on:{"handleSizeChange":_vm.handleSizeChange,"handleCurrentChange":_vm.handleCurrentChange},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.name))]),(row.ali_certify_status == 3 || row.ali_certify_status == 4)?_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('el-image',{staticStyle:{"width":"15px","height":"15px"},attrs:{"fit":"cover","src":require('@/assets/img/zmxy.png')}}),_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("芝麻企业信用认证")])],1):_vm._e()]}},{key:"action",fn:function(ref){
var row = ref.row;
return [(row.type === '2' || row.type === '5')?_c('el-button',{attrs:{"type":"text","size":"mini"},on:{"click":function($event){return _vm.pushPage(row,1)}}},[_vm._v("招聘岗位")]):_vm._e(),(row.type === '2' || row.type === '5')?_c('el-button',{attrs:{"type":"text","size":"mini"},on:{"click":function($event){return _vm.pushPage(row,2)}}},[_vm._v("账户信息")]):_vm._e(),(row.type === '2' || row.type === '5')?_c('el-button',{attrs:{"type":"text","size":"mini"},on:{"click":function($event){return _vm.handleFreeze(row, row.type)}}},[_vm._v(_vm._s(row.type === '2' ? '下架企业' : '上架企业'))]):_vm._e(),(row.type === '2' || row.type === '5')?_c('el-button',{attrs:{"type":"text","size":"mini"},on:{"click":function($event){return _vm.showDetail(row)}}},[_vm._v("查看")]):_vm._e()]}}],null,false,1018951409)})]:_vm._e(),(this.table.params.type === 6)?[_c('VTable',{attrs:{"has-pagionation":"","title":"","addText":"添加","field":_vm.field3,"loading":_vm.table.loading,"data":_vm.table.data,"page":_vm.table.params.page,"pageSize":_vm.table.params.count,"total":_vm.table.total,"tree-props":{children: 'children', hasChildren: 'has_child'},"hasPagionation":true,"default-expand-all":true,"showOverflowTooltip":true},on:{"handleSizeChange":_vm.handleSizeChange,"handleCurrentChange":_vm.handleCurrentChange},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var row = ref.row;
return [_c('el-button',{staticClass:"delBtn",attrs:{"type":"text","size":"mini","icon":"el-icon-delete"},on:{"click":function($event){return _vm.handleDetele(row)}}},[_vm._v("删除")])]}}],null,false,1205698194)})]:_vm._e()],2),_c('detail',{ref:"detail",on:{"refresh":_vm.refresh}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }