<template>
  <div class="proposal__wrap">
    <div class="section shadow filter__wrap">
      <div>
        <el-radio-group v-model="table.params.type" @change="handleTabs">
          <el-radio-button :label="1">待审核</el-radio-button>
          <el-radio-button :label="2">企业列表</el-radio-button>
          <el-radio-button :label="3">未充值</el-radio-button>
          <el-radio-button :label="4">审核失败</el-radio-button>
          <el-radio-button :label="5">下架企业</el-radio-button>
          <el-radio-button :label="6">待完善</el-radio-button>
        </el-radio-group>
      </div>
      <div class="flex">
        <el-form ref="elFormDom" inline :model="table.params">
          <el-form-item label="" prop="keyword">
            <el-input clearable v-model="table.params.keyword" placeholder="请输入企业名称"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询 </el-button>
          </el-form-item>
        </el-form>
      </div>
      
    </div>
    <!-- 表格 -->
    <div class="table__wrap">
      <!-- 1.待审核 3.未充值 4.审核失败 -->
      <template v-if="this.table.params.type === 1 || this.table.params.type === 3 || this.table.params.type === 4">
        <VTable
          has-pagionation
          title=''
          addText='添加'
          :field="field"
          :loading="table.loading"
          :data="table.data"
          :page='table.params.page'
          :pageSize='table.params.count'
          :total='table.total'
          :tree-props="{children: 'children', hasChildren: 'has_child'}"
          :hasPagionation="true"
          :default-expand-all="true"
          :showOverflowTooltip="true"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        > 
          <template v-slot:name="{row}">
            <div>{{row.name}}</div>
            <!--  芝麻企业信用认证 ali_certify_status 大于3时才显示图标 -->
            <div v-if="row.ali_certify_status > 3" style="display: flex;align-items: center;">
              <el-image style="width: 15px; height: 15px;" fit="cover" :src="require('@/assets/img/zmxy.png')"></el-image>
              <span style="font-size:12px;">芝麻企业信用认证</span>
            </div>
          </template>
          <template v-slot:action="{row}">
            <el-button v-if="row.type === '1'" type="text" size="mini" @click="showDetail(row)">审核</el-button>
            <el-button v-if="row.type === '3' || row.type === '4'" type="text" size="mini" @click="showDetail(row)">查看</el-button>  
          </template>
        </VTable>
      </template>
      <!-- 2.企业列表 5.下架企业 -->
      <template v-if="this.table.params.type === 2 || this.table.params.type === 5">
        <VTable
          has-pagionation
          title=''
          addText='添加'
          :field="field2"
          :loading="table.loading"
          :data="table.data"
          :page='table.params.page'
          :pageSize='table.params.count'
          :total='table.total'
          :tree-props="{children: 'children', hasChildren: 'has_child'}"
          :hasPagionation="true"
          :default-expand-all="true"
          :showOverflowTooltip="true"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        >
          <template v-slot:name="{row}">
            <div>{{row.name}}</div>
            <!--  芝麻企业信用认证 ali_certify_status 大于3时才显示图标 -->
            <div v-if="row.ali_certify_status == 3 || row.ali_certify_status == 4" style="display: flex;align-items: center;">
              <el-image style="width: 15px; height: 15px;" fit="cover" :src="require('@/assets/img/zmxy.png')"></el-image>
              <span style="font-size:12px;">芝麻企业信用认证</span>
            </div>
          </template>
          <template v-slot:action="{row}">
            <el-button v-if="row.type === '2' || row.type === '5'" type="text" size="mini" @click="pushPage(row,1)">招聘岗位</el-button>
            <el-button v-if="row.type === '2' || row.type === '5'" type="text" size="mini" @click="pushPage(row,2)">账户信息</el-button>
            <el-button v-if="row.type === '2' || row.type === '5'" type="text" size="mini" @click="handleFreeze(row, row.type)">{{row.type === '2' ? '下架企业' : '上架企业'}}</el-button>
            <el-button v-if="row.type === '2' || row.type === '5'" type="text" size="mini" @click="showDetail(row)">查看</el-button>
          </template>
        </VTable>
      </template>
      <!-- 6.待完善 -->
      <template v-if="this.table.params.type === 6">
        <VTable
          has-pagionation
          title=''
          addText='添加'
          :field="field3"
          :loading="table.loading"
          :data="table.data"
          :page='table.params.page'
          :pageSize='table.params.count'
          :total='table.total'
          :tree-props="{children: 'children', hasChildren: 'has_child'}"
          :hasPagionation="true"
          :default-expand-all="true"
          :showOverflowTooltip="true"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        >
          <template v-slot:action="{row}">
            <el-button type="text" size="mini" icon="el-icon-delete" class="delBtn" @click="handleDetele(row)">删除</el-button> 
          </template>
        </VTable>
      </template>
    </div>
    <detail ref="detail" @refresh="refresh"></detail>
     
  </div>
</template>

<script>
import VTable from '@/components/VTable'
import Detail from './components/Detail.vue'
import {setStorage, getStorage} from '@/storage/index.js'
import { mixinTable } from '@/mixins/table.js'
export default {
  name: 'CompanyList',
  mixins:[mixinTable],
  components: {
    VTable,
    Detail,
  },
  data() {
    return {
      field: [
        { name: "name", label: "企业名称",width:'180', hidden: false },
        { name: "industry_name", label: "行业类型", hidden: false },
        { name: "legal", label: "负责人", width:"100", hidden: false },
        { name: "phone", label: "注册账号", hidden: false },
        { name: "create_time", label: "注册日期", hidden: false },
        { name: "commit_at", label: "提交时间", hidden: false },
        { name: "action", label: "操作", width: "120", hidden: false }
      ],
      field2: [
        { name: "name", label: "企业名称",width:'180', hidden: false },
        { name: "industry_name", label: "行业类型", hidden: false },
        { name: "legal", label: "负责人", width:"100", hidden: false },
        { name: "phone", label: "注册账号", hidden: false },
        { name: "create_time", label: "注册日期", hidden: false },
         { name: "commit_at", label: "提交时间", hidden: false },
        { name: "pass_at", label: "激活日期", hidden: false },
        { name: "action", label: "操作", width: "280", hidden: false }
      ],
      field3: [
        { name: "phone", label: "注册账号", hidden: false },
        { name: "create_time", label: "注册日期", hidden: false },
         { name: "commit_at", label: "提交时间", hidden: false },
        { name: "action", label: "操作", width: "120", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          keyword: '',
          type: getStorage('companyStatus') || 1, // 类型 1:待审核 2:已激活 3:未充值 4:审核失败 5:下架企业 6:待完善
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      this.table.data = [];
      this.$http.get('/admin/company/list',  {params: this.table.params}).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 切换状态
    handleTabs(value) {
      this.table.params.page = 1;
      this.table.data = [];
      setStorage('companyStatus',value)
      this.getTable();
    },
    //  2下架、5上架
    handleFreeze(row, type) {
      console.log(type)
      let str = type === '2' ? '下架' : '上架'
      let _params = {
        id: row.id,
        type: type
      }
      this.$confirm(`请确认是否${str}该企业？`, '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消'
      }).then(() =>{
        this.$http.post('/admin/company/operation', _params).then(res => {
          if(res.code === 1) {
            this.$message.success('操作成功！')
            this.getTable();
          } else {
            this.$message.error(res.msg)
          }
        })
      }).catch(() => {})
    },
    // 操作记录 type:1招聘岗位、2.账号信息
    pushPage(row,type) {
      sessionStorage.setItem('companyId', row.id)
      let _url = type === 1 ? '/quarters' : '/account'
      this.$router.push({
        path: _url,
        query: {
          companyName: row.name
        }
      })
    },
     // 审核 
    showDetail(row) {
      let dom = this.$refs.detail
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },

    // 删除
    handleDetele(row) {
      console.log(row)
    },
    refresh(status) {
      this.table.params.status === status
      this.getTable();
    }
  }
}
</script>

<style scoped lang="scss">
  .flex {
    display: flex;
  }
  .proposal__wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px;
    position: relative;

    .filter__wrap {
      padding: 10px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
    }

    .table__wrap {
      flex: 1;
      padding: 0;
      box-sizing: border-box;
    }
  }
</style>