<template>
  <div class="detail__wrap" v-show="visible">
    <el-breadcrumb separator-class="el-icon-arrow-right" class="crumbs__wrap">
      <el-breadcrumb-item ><span @click="toggle(false)" style="font-weight:bold;cursor: pointer;">企业管理</span></el-breadcrumb-item>
      <el-breadcrumb-item>企业审核</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="section shadow">
      <div class="main">
        <el-form
          class="staff-from"
          v-loading="loading"
          element-loading-text="加载中…"
          ref='elFormDom'
          label-width='110px'
          label-position='top'
          :model='form.data'
          size="mini"
        >
          <el-row>
            <el-col :span="12">
              <el-form-item label="企业头像">
                <el-image
                  style="width: 160px; height: 160px"
                  :src="form.data.avatar"
                  fit="cover"
                  :preview-src-list="[form.data.avatar]"></el-image>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-row>
                <el-col :span="24">
                  <el-form-item label="公司名称">
                    <el-input v-model="form.data.name" :readonly="true"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="行业类型">
                    <el-input v-model="form.data.industry_name" :readonly="true"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="法人姓名">
                    <el-input v-model="form.data.legal_name" :readonly="true"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="负责人">
                <el-input v-model="form.data.legal" :readonly="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系电话">
                <el-input v-model="form.data.phone" :readonly="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="公司类型">
                <el-input v-model="form.data.nature" :readonly="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="公司规模">
                <el-input v-model="form.data.scale" :readonly="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="公司介绍">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 10}"
                  v-model="form.data.introduce">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="信用代码">
                <el-input v-model="form.data.xy_code" :readonly="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="注册时间">
                <el-input v-model="form.data.register_date" :readonly="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="注册资金">
                <el-input v-model="form.data.register_money	" :readonly="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="所在地区">
                <el-input v-model="form.data.area_str" :readonly="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="详细地址">
                <el-input v-model="form.data.address" :readonly="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="营业执照">
                <el-image
                  style="width: 200px; height: 200px"
                  :src="form.data.business"
                  fit="cover"
                  :preview-src-list="[form.data.business]"></el-image>
              </el-form-item>
            </el-col>
            <el-col :span="24" >
              <el-form-item label="公司照片" style="width:1200px">
                <el-image
                  v-for="item in form.data.image"
                  :key="item"
                  style="width: 200px; height: 200px;margin-right:5px;border:solid 1px #eee"
                  :src="item"
                  fit="cover"
                  :preview-src-list="form.data.image"></el-image>
              </el-form-item>
            </el-col>
            <el-col :span="24" v-if="!!form.data.reject_reson">
              <el-form-item label="拒绝理由">
                <el-input v-model="form.data.reject_reson" :readonly="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <!-- 待审核时展示 -->
    <div class="section shadow" style="margin-top:20px;padding:10px" v-if="this.reportType == 1">
      <el-row type="flex" justify="center">
        <el-button type="primary" :loading="form.loading" @click="handleAccept">通过</el-button>
        <el-button  @click="handleReject">拒绝</el-button>
      </el-row>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'ReportDetail',
    data() {
      return {
        visible: false,
        loading: false,
        reportType: 1, // 1:待审核 2:已激活 3:未充值 4:审核失败 5:下架企业 6:待完善
        form: {
          data: {
            image: [],
          }
        },
      }
    },
    created() {
      this.getDetail();
    },
    methods: {
      getDetail(row) {
        if(!!row) {
          this.reportType = row.type;
          let _params = {}
          if(row.type == 1) {
            _params = {
              id: row.id,
              is_check: 1, //(待审核时加条件)是否审核页面：0-否；1-是；
            }
          } else {
            _params = {
              id: row.id,
            }
          }
          this.$http.get('/admin/company/info', {params: _params}).then(res => {
            this.form.data = res.data
            this.form.data.area_str = res.data.province + res.data.city + res.data.area
          })
        }
      },
      // 通过
      handleAccept() {
        this.$confirm('请确认是否通过审核？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
         let _params = {
            id: this.form.data.id,
            type: 1, // 类型 1:通过 2:拒绝
          }
          this.$http.post('/admin/company/check', _params).then(res => {
            if(res.code === 1) {
              this.$message.success('操作成功！');
              this.toggle(false)
              this.$emit('refresh', 2)
            } else {
               this.$message.success(res.msg);
            }
          })
        }).catch(() => {});
      },
      // 拒绝
      handleReject() {
        this.$prompt('请输入拒绝理由', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(({ value }) => {
          if(!value) {
            return this.$message.warning('请输入拒绝理由！')
          }
          let _params = {
            id: this.form.data.id,
            type: 2, // 类型 1:通过 2:拒绝
            reject_reson: value
          }
          this.$http.post('/admin/company/check', _params).then(res => {
            if(res.code === 1) {
              this.$message.success('操作成功！');
              this.toggle(false)
              this.$emit('refresh')
            } else {
               this.$message.error(res.msg);
            }
          })
        }).catch(() => {});
      },
      reset(done) {
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
    }
  }
</script>
<style scoped lang="scss">
.detail__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
  background-color: #f5f5f5;
  padding: 20px;
  z-index: 9;
  overflow-y: scroll;

  .crumbs__wrap{
    margin-bottom: 20px;
  }

  .main {
    width: 800px;
    background-color: #fff;
    padding: 20px;
  }
}

</style>